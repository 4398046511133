document.querySelectorAll('a[href^="#"]').forEach(el => {
  el.addEventListener('click', (evt) => {
    evt.preventDefault();

    const id = el.getAttribute('href')?.replace('#', '');

    if(!id) {
      console.error("No id found for anchor link", el);
      return;
    }

    const target = document.getElementById(id);
    if(!target) {
      console.error("No element found for anchor link", id, el);
      return;
    }

    target.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest"})
  });
});
