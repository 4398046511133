import "@css/app.css";

// Put Alpine last, so that it has access to other JS modules
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

import "./anchor-links";
import "./carousel";

import { animate, inView, stagger } from "motion"

document.querySelectorAll('.motion-text-split').forEach(el => {
  const newel = el.cloneNode();
  newel.innerHtml = '';

  const words = el.innerText.trim().split(' ');
  words.forEach((x, index) => {
    x = x.trim();
    if(x != '') {
      const y = document.createElement('span');
      y.innerText = x;
      y.classList.add('inline-block');
      y.style.opacity = 0;

      const space = document.createDocumentFragment();
      space.textContent = (index < words.length - 1 ? ' ' : '');
      newel.appendChild(y);
      newel.appendChild(space);
    }
  });
  el.replaceWith(newel);

  inView(newel, x => {
    animate(newel.querySelectorAll('span'), { opacity: 1, y: [50, 0], x: [0, 0] }, { delay: stagger(0.08) })
  });

});

document.querySelectorAll('.motion-bounce').forEach(el => {
  for (const child of el.children) {
    child.style.opacity = 0;
    inView(child, x => {
      animate(child, { opacity: 1, scale: 1, y: [150, 0], x: [0, 0] }, { delay: 0.05 })
    });

  }

});

(() => {
  document.querySelectorAll('button.next-block').forEach(el => {

      el.addEventListener('click', (evt) => {
        evt.preventDefault();

        let parent = el;
        let found = false;
        while(parent != null && !found) {
          parent = parent.parentElement;
          if(parent.classList.contains('content-block')) {
            found = true;
          }
        }

        if(parent?.nextElementSibling?.classList.contains('content-block')) {
          parent.nextElementSibling.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest"})
        }
      });

  });
})();

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
