
import EmblaCarousel from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";
import Autoscroll from "embla-carousel-auto-scroll";

document.querySelectorAll(".embla").forEach(container => {
  const viewport = container.querySelector('.embla-viewport');

  const options = { loop: true };

  const plugins = [];
  if(container.classList.contains('auto-scroll')) {
    plugins.push(Autoscroll({
      stopOnMouseEnter: true,
      stopOnFocusIn: true,
      stopOnInteraction: false,
      speed: 0.5,
      rootNode: () => container
    }));
  }

  const embla = EmblaCarousel(viewport, options, plugins);
  const left = container.querySelector('.go-left');
  const right = container.querySelector('.go-right');
  left.addEventListener('click', embla.scrollPrev, false)
  right.addEventListener('click', embla.scrollNext, false)
});

